import React, { useState, useContext, useEffect } from "react";
import "./formValidacionEmpresa.scss";
import Modal from "../../atoms/modal/modal";
import Button from "../../atoms/button-demp/button";
import GlobalContext from "../../../contexts/globalContext";
import InputForm from "../../atoms/input-form-demp/inputForm";
import iconoConfirmacion from "../../../assets/icons/modal_confirmacion.svg";
import iconoAlerta from "../../../assets/icons/modal_alerta.svg";
import {
   getValidacionAportes,
   getValidacionAfiliacion,
   xmlToJson,
} from "../../../services/form-demp.intermediation";
import Loading from "../../atoms/loading/loading";
import { getAccessToken } from "../../../services/accessToken";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";


const FormValidacionEmpresa = (props: any) => {
      const options = {
         renderNode: {
            [BLOCKS.PARAGRAPH]: (_node, children) => <p>{children}</p>,
            [BLOCKS.UL_LIST]: (_node, children) => <ul>{children}</ul>,
            [BLOCKS.OL_LIST]: (_node, children) => <ol>{children}</ol>,
            [BLOCKS.LIST_ITEM]: (_node, children) => <li>{children}</li>,
            [INLINES.HYPERLINK]: ({ data }, children) => (
               <a
                     className="gtmFormularioTrabajoPropositoFormularioRequisitos"
                     href={data.uri}
                     target={"_blank"}
                     rel={"noopener noreferrer"}
               >
                     {children}
               </a>
            ),
         },
         renderText: (text) =>
            text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
   };
    const formFields = props;

    const context = useContext(GlobalContext);

   const [affiliatedCompanyModalOpen, setAffiliatedCompanyModalOpen] = useState(
      false
   );
   const [
      notLegalrepresentativeModalOpen,
      setNotLegalrepresentativeModalOpen,
   ] = useState(false);
   const [isAffiliatedCompany, setIsAffiliatedCompany] = useState(false);
   const [showErrors, setShowErrors] = useState(false);
   const [errorText, setErrorText] = useState("");
   const [loading, setLoading] = useState(false);
   const [errorModalOpen, setErrorModalOpen] = useState(false);

    let defaultFormValues = {};
    let mandatoryFields: string[] = [];
    formFields[0].referenciaFormulario.map((field) => {
        defaultFormValues[field.nombreId] = "";
        if (field.obligatorio) {
            mandatoryFields.push(field.nombreId);
        }
    });

    const [formValues, setFormValues] = useState(defaultFormValues);

    const updateFormValue = (field: string, value: any) => {
        setFormValues({ ...formValues, [field]: value });
    };
    

    const isFormValid = () => {
        return Object.entries(formValues).every(
            ([key, value]) => !mandatoryFields.includes(key) || value !== ""
        );
    };

    const errorDetected = (input): boolean => {
        if (
            mandatoryFields.includes(input.nombreId) &&
            formValues[input.nombreId] == "" &&
            showErrors
        ) {
            return true;
        } else {
            return false;
        }
    };

   const createInput = (input, index) => {
      return (
         <div
            className="input__container__first"
            key={index}
            id={`container_${input.nombreId}`}
         >
                <h6
                    className={`input__container__h6 ${
                        input.obligatorio ? "required" : ""
                    }`}
                >
                    {input.inputPrincipal}
                </h6>
                <InputForm
                    key={`inp${index}`}
                    title={input.inputPrincipal}
                    type={input.tipoCampo}
                    nameId={input.nombreId}
                    value={formValues[input.nombreId]}
                    handleChange={(e) => updateFormValue(input.nombreId, e)}
                    isError={errorDetected(input)}
                    errorMessage={input.mensajeError}
                    options={input.listaSelect || null}
                    placeholder={
                        ["docNum"].includes(input.nombreId)
                            ? "Digita tu número de documento"
                            : input.textoTerminos
                    }
                />
                <div>
                    {input.tituloBotonTerminos && (
                        <p className="small-note">
                            {input.tituloBotonTerminos}
                        </p>
                     )}
                    {input.textoTerminos && (
                       <p className="small-note">
                          {documentToReactComponents(
                             input.textoTerminos.json,
                             options
                           )}
                       </p>
                          )}
                </div>
            </div>
        );
    };

    const canContinue = () => {
        if (isFormValid()) {
            submitForm();
            setShowErrors(false);
        } else {
            for (const field of mandatoryFields) {
                if (formValues[field] == "" || formValues[field] == false) {
                    document.getElementById(field)?.scrollIntoView({
                        behavior: "auto",
                        block: "center",
                        inline: "center",
                    });
                    break;
                }
            }
            setShowErrors(true);
        }
    };

   const submitForm = async () => {
      setLoading(true);
      const token = await (await getAccessToken()).data.data.id_token;
      const xmlBody = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:urn="urn:comfama.com.co:subsidio:pscd_servicio_aportante">\r\n   <soapenv:Header/>\r\n<soapenv:Body>\r\n<urn:SUBSIDIO_PSCD_Servicio_Aportante_MT>\r\n<TIPO_ID>${formValues["docType"]}</TIPO_ID>\r\n<NUMERO_ID>${formValues["docNum"]}</NUMERO_ID>\r\n</urn:SUBSIDIO_PSCD_Servicio_Aportante_MT>\r\n</soapenv:Body>\r\n</soapenv:Envelope>`;

      await getValidacionAfiliacion(xmlBody,token)
            .then((response) => {
            const data = xmlToJson(response.data);
            if (data.T_DETALLE_APORTANTE.ESTADO !== "VIGEN") {
               setIsAffiliatedCompany(false);
               localStorage.setItem("isAffiliatedCompany", "false");
            } else {
               setIsAffiliatedCompany(true);
               localStorage.setItem("isAffiliatedCompany", "true");
            }
            return getValidacionAportes(
               formValues["docType"],
               formValues["docNum"],
               token
            );
         })
         .then((response) => {
            if (response.data.data.tieneMora) {
               setIsAffiliatedCompany(false);
            }
            setAffiliatedCompanyModalOpen(true);
            setLoading(false);
         })
         .catch((err) => {
            if (err.response) {
               setErrorText(err.response.data.userMessage);
            } else if (err.request) {
               setErrorText(
                  "No fue posible comunicarse con el servicio, por favor intente nuevamente"
               );
            } else {
               setErrorText(
                  "No fue posible comunicarse con el servicio, por favor intente nuevamente"
               );
            }
            setLoading(false);
            setErrorModalOpen(true);
         });
   };

   const validarRepresentanteLegal = async (
      tipoDoc: string,
      numeroDoc: string,
   ) => {
      if (isAffiliatedCompany != true) {
         return;
      }
      setLoading(true);
      const token = await (await getAccessToken()).data.data.id_token;
      const xmlBody = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:urn="urn:comfama.com.co:subsidio:pscd_servicio_aportante">\r\n   <soapenv:Header/>\r\n<soapenv:Body>\r\n<urn:SUBSIDIO_PSCD_Servicio_Aportante_MT>\r\n<TIPO_ID>${tipoDoc}</TIPO_ID>\r\n<NUMERO_ID>${numeroDoc}</NUMERO_ID>\r\n</urn:SUBSIDIO_PSCD_Servicio_Aportante_MT>\r\n</soapenv:Body>\r\n</soapenv:Envelope>`;

      await getValidacionAfiliacion(xmlBody,token)
         .then((response) => {
            const data = xmlToJson(response.data);
            if(tipoDoc === "CO1N") {
               if (data.NUM_ID_REP_LEGAL === context.gigyaContext.gigyaAccount.data.docNumber) {
                  localStorage.setItem("nameCompany", data.RAZON_SOCIAL);
                  localStorage.setItem("docNum", formValues.docNum);
                  localStorage.setItem("isLegalRepresentative", "true");
                  window.location.href =
                     "/desarrollo-empresarial/autorizacion-datos-y-condiciones";
               } else {
                  setNotLegalrepresentativeModalOpen(true);
                  localStorage.setItem("isAffiliatedCompany", "");
                  localStorage.setItem("isLegalRepresentative", "");
               }
            } else {
               if (data.NUMERO_ID === context.gigyaContext.gigyaAccount.data.docNumber) {
                  localStorage.setItem("nameCompany", data.RAZON_SOCIAL);
                  localStorage.setItem("docNum", formValues.docNum);
                  localStorage.setItem("isLegalRepresentative", "true");
                  window.location.href =
                     "/desarrollo-empresarial/autorizacion-datos-y-condiciones";
               } else {
                  setNotLegalrepresentativeModalOpen(true);
               }
            }
            setLoading(false);
         })
         .catch((err) => {
            if (err.response) {
               setErrorText(err.response.data.userMessage);
            } else if (err.request) {
               setErrorText(
                  "No fue posible comunicarse con el servicio, por favor intente nuevamente"
               );
            } else {
               setErrorText(
                  "No fue posible comunicarse con el servicio, por favor intente nuevamente"
               );
            }
            setLoading(false);
            setErrorModalOpen(true);
         });
   };

   useEffect(() => {
      if (context?.gigyaContext?.gigyaAccount) {
            validarRepresentanteLegal(
            formValues["docType"],
            formValues["docNum"],
         );
      }
   }, [context.gigyaContext.gigyaAccount]);

   useEffect(() => {
      localStorage.removeItem("docNum");
      localStorage.removeItem("isLegalRepresentative");
      localStorage.removeItem("isAffiliatedCompany");
      localStorage.removeItem("nameCompany");
   }, []);

   const notAffiliatedCompanyText = () => {
      return (
         <React.Fragment>
            <img src={iconoAlerta} />
            <h1 className="o-form-validacion-empresa-requirements__content__header">
               Datos no verificados
            </h1>
            <p className="o-form-validacion-empresa-requirements__content__info">
               Tu empresa no está registrada como afiliada en nuestra base de datos o presentas alguna novedad en los aportes. 
            </p>
            <p className="o-form-validacion-empresa-requirements__content__info">
               Revisa que hayas ingresado adecuadamente el tipo y número de identificación.
            </p>
            <p className="o-form-validacion-empresa-requirements__content__info">
               Te invitamos a afiliarte o ponerte en contacto con los canales de atención para resolver tus inquietudes o novedades referentes a tu afiliación. 604 3607080 (para Valle de Aburrá) y  018000 415 455 (para el resto de Antioquia) o escríbenos al correo <a href="mailto:desarrolloempresarial@comfama.com.co">desarrolloempresarial@comfama.com.co</a>. 
            </p>
            <hr />
            <br />
            <p className="o-form-validacion-empresa-requirements__content__info">
               Haz clic 
               <a
                  href="https://www.comfama.com/portal-servicios/personas/afiliaciones"
                  target="_blank"
                  rel="noreferrer"
               >
               &nbsp;aquí&nbsp;
               </a>
                para recibir más información.
            </p>
            <Button
               id={"btn-continuar"}
               type={"button"}
               classname={`secondary`}
               text={"Volver" || "Go back"}
               isDisabled={false}
               onClickAction={() => {
                  setAffiliatedCompanyModalOpen(false)
               }}
            />
         </React.Fragment>
      );
   };

   const affiliatedCompanyText = () => {
      return (
         <React.Fragment>
            <img src={iconoConfirmacion} />
            <h1 className="o-form-validacion-empresa-requirements__content__header">
               Datos verificados
            </h1>
            <p className="o-form-validacion-empresa-requirements__content__info">
               Tu empresa está registrada como afiliada en nuestras bases de datos y te encuentras al día en aportes. 
            </p>
            <p className="o-form-validacion-empresa-requirements__content__info">
               Para continuar el proceso debes pasar a la etapa de inscripción
               como representante legal o titular de la empresa.
            </p>
            <p className="o-form-validacion-empresa-requirements__content__info">
               Recuerda tener a la mano registro mercantil, certificado de ingresos emitido por el contador, la planilla integrada de liquidación de aportes PILA y carta de intención de participación.  
            </p>
            <Button
               id={"btn-continuar"}
               type={"button"}
               classname={`secondary`}
               text={"Continuar" || "Send"}
               isDisabled={false}
               onClickAction={() => {
                  setAffiliatedCompanyModalOpen(false);
                  if (!context.gigyaContext.gigyaAccount) {
                     context.gigyaContext.login();
                  } else {
                     validarRepresentanteLegal(
                        formValues["docType"],
                        formValues["docNum"],
                     );
                  }
                  
               }}
            />
         </React.Fragment>
      );
   };

   const notValidLegalRepresentative = () => {
      return (
         <React.Fragment>
            <img src={iconoAlerta} />
            <h1 className="o-form-validacion-empresa-requirements__content__header">
               Novedad en la verificación 
            </h1>
            <p className="o-form-validacion-empresa-requirements__content__info">
               Para continuar el proceso debe ser el representante legal o titular de la empresa quien realice el proceso de inscripción. 
            </p>
            <hr />
            <br />
            <p className="o-form-validacion-empresa-requirements__content__info">
               Te invitamos a cerrar sesión con tu usuario Comfama para iniciar el proceso nuevamente. 
            </p>
            <Button
               id={"btn-continuar"}
               type={"button"}
               classname={`secondary`}
               text={"Volver" || "Go back"}
               isDisabled={false}
               onClickAction={() => {
                  setNotLegalrepresentativeModalOpen(false);
               }}
            />
         </React.Fragment>
      );
   };

   const errorModal = () => {
      return (
         <React.Fragment>
            <img src={iconoAlerta} />
            <h1 className="o-form-validacion-empresa-requirements__content__header">
               Error
            </h1>
            <p className="o-form-validacion-empresa-requirements__content__info">
               {errorText}
            </p>
            <Button
               id={"btn-continuar"}
               type={"button"}
               classname={`secondary`}
               text={"Volver" || "Go back"}
               isDisabled={false}
               onClickAction={() => {
                  setErrorModalOpen(false);
               }}
            />
         </React.Fragment>
      );
   };

   return (
      <div>
         {loading && <Loading />}
         {!loading && (
            <div className="o-form-validacion-empresa">
               <div
                  id="groupIntermediacion0"
                  className="o-form-validacion-empresa__group--open"
               >
                  <div className="o-form-validacion-empresa__group-cont">
                     {formFields &&
                        formFields[0].referenciaFormulario.map((field, i) =>
                           createInput(field, i)
                        )}
                  </div>
               </div>
               <div className="o-form-validacion-empresa__bottom">
                  <div className="o-form-validacion-empresa__bottom-btns">
                     <div className="o-form-validacion-empresa__bottom-btn">
                        <Button
                           id={"btn-continuar"}
                           type={"button"}
                           classname={`primary`}
                           text={"Volver" || "Back"}
                           isDisabled={false}
                           onClickAction={() => {}}
                        />
                        <Button
                           id={"btn-continuar"}
                           type={"button"}
                           classname={`secondary`}
                           text={"Continuar" || "Send"}
                           isDisabled={false}
                           onClickAction={() => {
                              canContinue();
                           }}
                        />
                     </div>
                  </div>
               </div>
               {
                  <Modal
                     open={affiliatedCompanyModalOpen}
                     className={"modal-validacion-empresa"}
                     onClose={() => {}}
                  >
                     <div className="o-form-validacion-empresa-requirements">
                        {!isAffiliatedCompany && (
                           <div className="o-form-validacion-empresa-requirements__icon">
                              <i
                                 className="icon__check icon-close"
                                 onClick={() =>
                                    setAffiliatedCompanyModalOpen(false)
                                 }
                              />
                           </div>
                        )}
                        <div className="o-form-validacion-empresa-requirements__content">
                           {!isAffiliatedCompany && notAffiliatedCompanyText()}
                           {isAffiliatedCompany && affiliatedCompanyText()}
                        </div>
                     </div>
                  </Modal>
               }
               {
                  <Modal
                     open={notLegalrepresentativeModalOpen}
                     className={"modal-validacion-empresa"}
                     onClose={() => {}}
                  >
                     <div className="o-form-validacion-empresa-requirements">
                        <div className="o-form-validacion-empresa-requirements__icon">
                           <i
                              className="icon__check icon-close"
                              onClick={() =>
                                 setNotLegalrepresentativeModalOpen(false)
                              }
                           />
                        </div>
                        <div className="o-form-validacion-empresa-requirements__content">
                           {notValidLegalRepresentative()}
                        </div>
                     </div>
                  </Modal>
               }
               {
                  <Modal
                     open={errorModalOpen}
                     className={"modal-validacion-empresa"}
                     onClose={() => {}}
                  >
                     <div className="o-form-validacion-empresa-requirements">
                        <div className="o-form-validacion-empresa-requirements__icon">
                           <i
                              className="icon__check icon-close"
                              onClick={() => setErrorModalOpen(false)}
                           />
                        </div>
                        <div className="o-form-validacion-empresa-requirements__content">
                           {errorModal()}
                        </div>
                     </div>
                  </Modal>
               }
            </div>
         )}
      </div>
   );
};

export default FormValidacionEmpresa;
