import React from "react";
import Breadcrumb from "../../atoms/breadcrumb/breadcrumb";
import "./bodyFormValidarEmpresa.scss";
import FormValidacionEmpresa from "../form-validacion-empresa/formValidacionEmpresa";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

interface IBodyForm {
    tituloPrincipal: string;
    descripcionFormulario: any;
    titulosFormulario: Array<any>;
    imagenesEmpleo: any;
}

const BodyFormValidarEmpresa = (props: IBodyForm) => {
    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (_node, children) => <p>{children}</p>,
            [BLOCKS.UL_LIST]: (_node, children) => <ul>{children}</ul>,
            [BLOCKS.OL_LIST]: (_node, children) => <ol>{children}</ol>,
            [BLOCKS.LIST_ITEM]: (_node, children) => <li>{children}</li>,
            [INLINES.HYPERLINK]: ({ data }, children) => (
                <a
                    className="gtmFormularioTrabajoPropositoFormularioRequisitos"
                    href={data.uri}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                >
                    {children}
                </a>
            ),
        },
        renderText: (text) =>
            text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
    };

    return (
        <div className="o-bodyForm-validacion-empresa">
            <Breadcrumb content={`Inicio / Ley de Emprendimiento`} />
            <div className={`o-bodyForm-validacion-empresa__header`}>
                <h1 className="o-bodyForm-validacion-empresa__header-title title-primary">
                    {props.tituloPrincipal}
                </h1>
                <div className="o-bodyForm-validacion-empresa__header-subtitle">
                    {documentToReactComponents(
                        props.descripcionFormulario.json,
                        options
                    )}
                </div>
            </div>
            <FormValidacionEmpresa {...props.titulosFormulario} />
        </div>
    );
};

export default BodyFormValidarEmpresa;
