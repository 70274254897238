import React from "react";
import Layout from "../../components/organisms/layout/layout";
import SEO from "../../shared/seo";
import BodyFormValidarEmpresa from "../../components/organisms/body-form-validacion-empresa/bodyFormValidarEmpresa";
import { useStaticQuery, graphql } from "gatsby";

const DesarrolloEmpresarialPage = () => {
	
	const data = useStaticQuery(graphql`
		query contentfulFormValidacionDesarrolloEmpresarial {  
			allContentfulContactenos(filter: { titulo: { eq: "Información Contáctanos Validación Desarrollo empresarial" } }) {
				nodes {
					tituloPrincipal,
					descripcionFormulario {
						json
					},
					titulosFormulario {
						tituloGeneral
						referenciaFormulario {
							tipoCampo
							listaSelect
							mensajeError
							inputPrincipal
							nombreId
							tituloBotonTerminos
							textoTerminos {
								json
							}
							obligatorio
						}
					}
					imagenesEmpleo {
						file {
							url
						}
						title
						fluid(maxWidth: 960, quality: 75) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
					}
				}
			}
		}
	`)
	
	return (
		<Layout hideHeaderDesktop={true} hideHeaderMobile={true} isNotRoot={true}>
			<SEO title="Desarrollo Empresarial" lang="es"/>
			<BodyFormValidarEmpresa {...data.allContentfulContactenos.nodes[0]} />
		</Layout>
	);
};
export default DesarrolloEmpresarialPage;
